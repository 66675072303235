import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Contact = () => (
  <Layout>
    <SEO title="Contact Colin" />
    <div className="article max-w-lg">
      <h1 className="text-4xl">Contact Colin</h1>
      <div className="max-w-md">
        <p>Please feel free to contact me if you have any queries about my work.</p>
        <form
          name="contact"
          method="post"
          action="/success"
          data-netlify="true"
          className="max-w-sm"
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <div className="block mb-6">
            <label className="block mb-2" htmlFor="name">
              Full Name
            </label>
            <input
              className="input"
              type="text"
              id="name"
              name="name"
              placeholder="John Smith"
              required
            />
          </div>
          <div className="block mb-6">
            <label className="block mb-2" htmlFor="email">
              Email Address
            </label>
            <input
              className="input"
              type="email"
              id="email"
              name="email"
              placeholder="john@smith.com"
            />
          </div>
          <div className="block mb-6">
            <label className="block mb-2" htmlFor="telephone">
              Telephone Number
            </label>
            <input
              className="input"
              type="text"
              id="telephone"
              name="telephone"
              placeholder="+44(0)1986 870 000"
            />
          </div>
          <div className="block mb-6">
            <label className="block mb-2" htmlFor="message">
              Your Message
            </label>
            <textarea className="input h-48" id="message" name="message" />
          </div>
          <div class="h-0 overflow-hidden" data-netlify-recaptcha="true"></div>
          <div className="block text-right">
            <button className="button button-invert" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  </Layout>
)

export default Contact
